import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
interface LoginRedirectProps {
  code?: string;
}
export default function LoginRedirect(props: LoginRedirectProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [body, setBody] = useState();
  useEffect(async () => {
    // 詳細を取得
    const grant_type = 'authorization_code';
    const code = searchParams.get('code');
    const clientId = 'chqllj0q907kptdauq9ls6sbo';
    const loginRedirectUri = 'https%3A%2F%2Fid.aritel.net/callback';
    const res = await fetch(
      'https://aritelnet.auth.us-east-1.amazoncognito.com/oauth2/token',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "Accept": "application/json"
        },
        body: `grant_type=${grant_type}&code=${code}&client_id=${clientId}&redirect_uri=${loginRedirectUri}`
      });
    setBody(await res.json());
  }, [searchParams]);
  return (
    <>ログインしたようです。{searchParams.get('code')} {new String(body)}</>
  );
}
