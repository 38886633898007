import React from 'react';
import { Routes, Route } from "react-router-dom";
import LoginRedirect from './callback/LoginRedirect';
import App from './App';
export default function Root() {
  return (
    <Routes>
      <Route path='/callback' element={ <LoginRedirect /> } />
      <Route path='/' element={ <App /> } />
    </Routes>
  );
}